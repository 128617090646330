import { loveholidaysOldColors, colors as rawColors, type LoveholidaysOldColors } from '@loveholidays/design-tokens';

export const colors: LoveholidaysOldColors = {
  ...loveholidaysOldColors,
  ...rawColors,

  // Avoid changing colours until given the go ahead that we want them to change.
  // https://loveholidays.slack.com/archives/C01JG4LCZCZ/p1729782784099059
  actioninformationPressed: '#023e76',
  actionlegacyprimarygreenDisabledlight: '#e6e8e9',
  baseWarning: '#bd5b00',
  iconDefault: '#071a26',
  textCriticaldark: '#e33d2d',
  textWarning: '#d79d66',
};
